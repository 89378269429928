import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';

import { FilePond } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

const { SubmitStartApplication } = require('./MiniAppFormFunctions');

const MiniAppFormForm = (props) => {
  const {
    showMiniAppForm,
    applicationFormLoading,
    miniAppFormJSON,
    gdprStatementJSON,
    jobSourcesJSON,
    setShowMiniAppForm,
    showMiniAppFormFrameLoading,
    setShowMiniAppFormFrameLoading,
    MiniAppFormIframe,
    setMiniAppFormIframe,
    miniAppFormSubmitted,
    setMiniAppFormSubmitted,
    MiniAppError,
    setMiniAppError,
    miniAppFormToken,
    setMiniAppFormToken,
    hasCVUploadedStatus,
    setHasCVUploadedStatus,
    siteConfig,
    trackEvent,
  } = props;

  useEffect(() => {
    console.debug('MiniAppError change => ', MiniAppError);
  }, [MiniAppError]);

  function createYupSchema(schema, config) {
    let _validationSchema = {};

    // Map through the schema to create the yup definition
    schema.map((schemaItem) => {
      // Add on field for GDPR Statement
      _validationSchema['gdprstatement_accept'] = yup
        .boolean()
        .required('You must accept the GDPR statement to apply')
        .oneOf([true], 'You must accept the GDPR statement to apply');

      // Add on field for job source
      _validationSchema['JobSource'] = yup
        .string()
        .required('You must supply a source for your application');

      // Add on field for cv upload
      _validationSchema['cv_upload'] = yup.mixed();

      if (miniAppFormJSON.CvRequired) {
        _validationSchema['cv_upload']
          .required('You must upload your CV to apply')
          .test('fileSize', 'You must upload your CV to apply', (value) => {
            if (value) {
              return true;
            }

            return false;
          });
      }

      // Add on field for cover letter upload
      _validationSchema['cover_upload'] = yup.mixed();

      if (miniAppFormJSON.CoverLetterRequired) {
        _validationSchema['cover_upload']
          .required('You must upload a cover letter to apply')
          .test(
            'fileSize',
            'You must upload a cover letter to apply',
            (value) => {
              if (value) {
                return true;
              }

              return false;
            }
          );
      }

      // Add on field for country if Postcode is found
      if (schemaItem.Identifier === 'Postcode') {
        _validationSchema['Country'] = yup.string().required();
      }

      // Assign the validation type according to the field type
      if (schemaItem.FieldType === 'number') {
        _validationSchema[schemaItem.Identifier] = yup.number();
      } else if (schemaItem.FieldType === 'boolean') {
        _validationSchema[schemaItem.Identifier] = yup.boolean();
      } else if (schemaItem.FieldType === 'boolean') {
        _validationSchema[schemaItem.Identifier] = yup.boolean();
      } else if (schemaItem.FieldType === 'date') {
        _validationSchema[schemaItem.Identifier] = yup.date();
      } else if (schemaItem.FieldType === 'array') {
        _validationSchema[schemaItem.Identifier] = yup.array();
      } else if (schemaItem.FieldType === 'object') {
        _validationSchema[schemaItem.Identifier] = yup.object();
      } else {
        _validationSchema[schemaItem.Identifier] = yup.string();
      }

      // Trim the value
      if (schemaItem.FieldType === 'text') {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].transform((value) => value.trim());
      }

      // Attach email validation depending on identifier
      if (
        schemaItem.Identifier === 'Email' ||
        schemaItem.Identifier === 'Confirm Email' ||
        schemaItem.Identifier === 'ConfirmEmail'
      ) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].email();
      }

      // Confirm email should validate the value of email
      if (
        schemaItem.Identifier === 'Confirm Email' ||
        schemaItem.Identifier === 'ConfirmEmail'
      ) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].oneOf([yup.ref('Email'), null], 'Both email address must match');
      }

      // Attach URL validation depending on identifier
      if (schemaItem.Identifier === 'URL') {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.FieldType
        ].url();
      }

      function getPCRegexByCountry(country) {
        switch (country) {
          case 'AU':
            return /^\\d{4}$/;
          case 'GB':
            return /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/;
          case 'US':
            return /^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$/;
          case 'CA':
            return /^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$/;
          case 'CN':
            return /^\\d{6}$/;
          case 'FR':
            return /^\\d{5}$/;
          case 'DE':
            return /^\\d{5}$/;
          case 'GG':
            return /^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$/;
          case 'IT':
            return /^\\d{5}$/;
          case 'JP':
            return /^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$/;
          case 'JE':
            return /^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$/;
          case 'KR':
            return /^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$/;
          case 'LU':
            return /^\\d{4}$/;
          case 'MX':
            return /^\\d{5}$/;
          case 'NL':
            return /^\\d{4}\\s{0,1}[A-Za-z]{2}$/;
          case 'NZ':
            return /^\\d{4}$/;
          case 'NO':
            return /^\\d{4}$/;
          case 'PL':
            return /^\\d{2}[- ]{0,1}\\d{3}$/;
          default:
            return /.*/;
        }
      }

      // Transform Postcode to uppercase and validate
      if (schemaItem.Identifier === 'Postcode' && schemaItem.Required) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ]
          .transform((value) => {
            return value !== null ? value.toUpperCase() : value;
          })
          .matches(
            getPCRegexByCountry(yup.ref('Country')),
            `The phone number entered couldn't be validated against the country selected.`
          );
      }

      // Transform Postcode to uppercase and validate
      if (
        (schemaItem.Identifier === 'Telephone1' ||
          schemaItem.Identifier === 'Telephone2') &&
        schemaItem.Required &&
        _validationSchema[schemaItem.Identifier].phone
      ) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].phone(
          yup.ref('Country'),
          true,
          `The phone number entered couldn't be validated against the country selected.`
        );
      }

      // Add regex
      if (
        schemaItem.RegEx &&
        schemaItem.Identifier !== 'Postcode' &&
        schemaItem.Identifier !== 'Telephone1' &&
        schemaItem.Identifier !== 'Telephone2'
      ) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].matches(schemaItem.RegEx, {
          message: 'The value did not pass the validation',
          excludeEmptyString:
            schemaItem.Required && schemaItem.Required === true ? false : true,
        });
      }

      // Add a min limit
      if (schemaItem.MinLength) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].min(
          schemaItem.MinLength,
          `The value must be at least ${schemaItem.MinLength} characters.`
        );
      }

      // Add a max limit
      if (schemaItem.MaxLength) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ].max(
          schemaItem.MaxLength,
          `The value must be no more than ${schemaItem.MinLength} characters.`
        );
      }

      // Make fields required
      if (schemaItem.Required && schemaItem.Required === true) {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ]
          .nullable(false)
          .required('This field is required');
      } else {
        _validationSchema[schemaItem.Identifier] = _validationSchema[
          schemaItem.Identifier
        ]
          .nullable(true)
          .notRequired();
      }
      return _validationSchema;
    });
  }

  const yepSchema = createYupSchema(miniAppFormJSON.config.Fields);
  const validateSchema = yup.object().shape(yepSchema);

  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      gdprstatement_accept: false,
      Country: 'AU',
      JobSource: siteConfig?.defaultJobSource || jobSourcesJSON[0],
    },
  });

  const [jobSource, setJobSource] = useState(jobSourcesJSON[0]);
  const [cvUploadData, setCvUploadData] = useState([]);
  const cvUploadSource = 'device';

  const [coverUploadData, setCoverUploadData] = useState([]);
  const [cvUploadError, setCvUploadError] = useState('');
  const [coverUploadError, setCoverUploadError] = useState('');

  const onSubmit = (data) => {
    let emailId = document.getElementById('Email');
    let confirmEmailId = document.getElementById('Confirm Email');

    if (
      miniAppFormJSON.CvRequired &&
      (!cvUploadData || cvUploadData.length <= 0)
    ) {
      setCvUploadError('This Field is required!');
    } else if (
      miniAppFormJSON.CoverLetterRequired &&
      (!coverUploadData || coverUploadData.length <= 0)
    ) {
      setCoverUploadError('This Field is required!');
    } else if (emailId.value !== confirmEmailId.value) {
      alert('Both email addresses must match');
    } else {
      trackEvent({
        eventCategory: 'MiniAppFormForm',
        eventAction: 'SubmitStartApplication',
        eventLabel: encodeURIComponent(data.encAdvertId),
      });

      if (cvUploadData && cvUploadData !== {}) {
        data.cv_upload = cvUploadData;
      }
      if (coverUploadData && coverUploadData !== {}) {
        data.cover_upload = coverUploadData;
      }

      SubmitStartApplication(
        siteConfig,
        data,
        setShowMiniAppForm,
        setShowMiniAppFormFrameLoading,
        setMiniAppFormIframe,
        miniAppFormSubmitted,
        setMiniAppFormSubmitted,
        MiniAppError,
        setMiniAppError,
        miniAppFormToken,
        setMiniAppFormToken,
        hasCVUploadedStatus,
        setHasCVUploadedStatus,
        trackEvent
      );
    }
  };

  const cvRef = useRef(null);
  const coverLetterRef = useRef(null);

  const ALLOWED_FILE_SIZE = 4000000;
  const ALLOWED_FILE_TYPES = /(\.doc|\.docx|\.txt|\.rtf|\.pdf)$/i;

  const handleCVUploadChange = (e, isFromUploader) => {
    if (!isFromUploader) {
      setValue('cv_upload', e.target.value.files);
    } else {
      if (e && e.length > 0 && e[0] && e[0].file) {
        const file = e[0].file;
        const cvName = file.name;
        const cvSize = file.size;

        if (!ALLOWED_FILE_TYPES.exec(cvName)) {
          setCvUploadError('Invalid File Type!');
          cvRef.current.removeFiles();
          return;
        } else if (cvSize > ALLOWED_FILE_SIZE) {
          setCvUploadError('The maximum file size allowed for upload is 4MB!');
          cvRef.current.removeFiles();
          return;
        }
        setValue('cv_upload', file);
        setCvUploadData(file);
        setCvUploadError('');
      } else {
        setValue('cv_upload', []);
        setCvUploadData([]);
      }
    }
  };

  const handleCoverUploadChange = (e, isFromUploader) => {
    if (!isFromUploader) {
      setValue('cover_upload', e.target.value.files);
    } else {
      if (e && e.length > 0 && e[0] && e[0].file) {
        const file = e[0].file;
        const clName = file.name;
        const clSize = file.size;

        if (!ALLOWED_FILE_TYPES.exec(clName)) {
          setCoverUploadError('Invalid File Type!');
          coverLetterRef.current.removeFiles();
          return;
        } else if (clSize > ALLOWED_FILE_SIZE) {
          setCoverUploadError(
            'The maximum file size allowed for upload is 4MB!'
          );
          coverLetterRef.current.removeFiles();
          return;
        }
        setValue('cover_upload', file);
        setCvUploadData(file);
        setCoverUploadError('');
      } else {
        setValue('cover_upload', []);
        setCoverUploadData([]);
      }
    }
  };

  return (
    <>
      {showMiniAppForm &&
      !applicationFormLoading &&
      miniAppFormJSON &&
      gdprStatementJSON &&
      gdprStatementJSON.Statement &&
      gdprStatementJSON.TickboxMessage &&
      miniAppFormJSON.config &&
      miniAppFormJSON.config.Fields &&
      !showMiniAppFormFrameLoading &&
      !miniAppFormSubmitted ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row justify-content-center"
        >
          <div className="VF_Alerts_Wrap smartfeed-forms-form col-12 col-lg-8">
            <div className="VF_Alerts_Inner_Wrap smartfeed-forms-page">
              <div className="application-title-wrap">
                <h2>Apply for this role</h2>
                <p>All fields are required unless indicated as optional</p>
              </div>
              {MiniAppError && (
                <div className="global-error">
                  <div className="global-error-wrapper">
                    {MiniAppError.Data &&
                    MiniAppError.Data.responseType &&
                    MiniAppError.Data.responseText ? (
                      <div
                        className={`${MiniAppError.Data.responseType}-global-error`}
                      >
                        <div>
                          <strong>
                            {MiniAppError.Data.responseType
                              .replace(/([A-Z])/g, ' $1')
                              .trim()}
                            :
                          </strong>
                          <span>{MiniAppError.Data.responseText}</span>
                        </div>
                        {MiniAppError.Data.responseType !==
                          'AlreadyAppliedError' && (
                          <div className="contact-support-global-error">
                            <p>
                              If this error persists please contact support at:{' '}
                              <a href="mailto:support@vacancy-filler.co.uk">
                                support@vacancy-filler.co.uk
                              </a>
                            </p>
                          </div>
                        )}
                      </div>
                    ) : MiniAppError.message ? (
                      <div className="general-global-error">
                        <div>
                          <strong>Error</strong>
                          <span>{MiniAppError.message}</span>
                        </div>
                        <div className="contact-support-global-error">
                          <p>
                            If this error persists please contact support at:{' '}
                            <a href="mailto:support@vacancy-filler.co.uk">
                              support@vacancy-filler.co.uk
                            </a>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="general-global-error">
                        <div>
                          <strong>Error</strong>
                          <div>
                            <pre>{JSON.stringify(MiniAppError, null, 2)}</pre>
                          </div>
                        </div>
                        <div className="contact-support-global-error">
                          <p>
                            If this error persists please contact support at:{' '}
                            <a href="mailto:support@vacancy-filler.co.uk">
                              support@vacancy-filler.co.uk
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <input
                name="advertId"
                value={miniAppFormJSON.encAdvertId}
                id="advertId"
                type="hidden"
                ref={register({
                  required: true,
                })}
              />
              <input
                name="token"
                value={miniAppFormToken}
                id="token"
                type="hidden"
                ref={register({
                  required: true,
                })}
              />
              <fieldset className="smartfeed-forms-fieldset">
                <legend>Your details</legend>
                {miniAppFormJSON.config.Fields.map((item, i) => (
                  <div className="row-fluid" key={i}>
                    <div className="smartfeed-forms-container col-md-12">
                      {item.Identifier === 'Postcode' && (
                        <>
                          <label htmlFor="Country">Country</label>
                          <select
                            name="Country"
                            className="form-control"
                            id="Country"
                            aria-invalid={errors['Country'] ? 'true' : 'false'}
                            ref={register({
                              required: true,
                              maxLength: item.MaxLength,
                              pattern: item.RegEx,
                            })}
                          >
                            <option value="AU">Australia</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States of America</option>
                            <option value="CA">Canada</option>
                            <option value="CN">China</option>
                            <option value="FR">France</option>
                            <option value="DE">Germany</option>
                            <option value="GG">Gurnsey</option>
                            <option value="IT">Italy</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="KR">South Korea</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MX">Mexico</option>
                            <option value="NL">Netherlands</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NO">Norway</option>
                            <option value="PL">Poland</option>
                            <option value="OTHER">Other</option>
                          </select>
                        </>
                      )}
                      {item.Identifier === 'JobSource' ? (
                        jobSourcesJSON &&
                        Array.isArray(jobSourcesJSON) && (
                          <>
                            <label htmlFor="JobSource">
                              Please tell us where you heard about this role
                              {item.Required && item.Required === true ? (
                                <span className="text-danger"> *</span>
                              ) : (
                                ' (Optional)'
                              )}
                            </label>
                            <select
                              name="JobSource"
                              className="form-control"
                              placeholder="Please select a source"
                              value={jobSource}
                              onChange={(e) => setJobSource(e.target.value)}
                              id="JobSource"
                              ref={register({
                                required:
                                  item.Required && item.Required === true,
                              })}
                            >
                              {jobSourcesJSON.map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </>
                        )
                      ) : (
                        <>
                          <label htmlFor={item.Identifier}>
                            {item.Label}
                            {item.Required && item.Required === true ? (
                              <span className="text-danger"> *</span>
                            ) : (
                              ' (Optional)'
                            )}
                          </label>
                          <input
                            name={item.Identifier}
                            id={item.Identifier}
                            placeholder={item.Label}
                            className="form-control"
                            aria-invalid={
                              errors[item.Identifier] ? 'true' : 'false'
                            }
                            type={`${
                              item.Identifier === 'Email' ? 'email' : 'text'
                            }`}
                            ref={register({
                              required: item.Required && item.Required === true,
                              maxLength: item.MaxLength,
                              pattern: item.RegEx,
                            })}
                            required={item.Required}
                          />
                          {errors[item.Identifier] && (
                            <>
                              {console.warn(
                                'Form error:',
                                errors[item.Identifier]
                              )}
                              {errors[item.Identifier].type === 'required' ? (
                                <span className="error error-required">
                                  This field is required
                                </span>
                              ) : errors[item.Identifier].message ? (
                                <span className="error error-required">
                                  {errors[item.Identifier].message}
                                </span>
                              ) : (
                                <span className="error">
                                  This field did not pass validation
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </fieldset>
              {(miniAppFormJSON.CvRequired ||
                miniAppFormJSON.CvRequired === 'true' ||
                siteConfig.optionalCVUploadEnabled) && (
                <fieldset className="smartfeed-forms-fieldset">
                  <legend>
                    Upload your CV
                    {miniAppFormJSON.CvRequired && (
                      <span className="text-danger"> *</span>
                    )}
                  </legend>
                  <p>
                    The accepted file types are: Microsoft Word (.doc, .docx),
                    Text files (.txt, .rtf), PDF files (.pdf)
                  </p>
                  <p>The maximum file size allowed for upload is 4MB</p>
                  {!miniAppFormJSON.CvRequired && (
                    <p>(This field is optional)</p>
                  )}
                  <label htmlFor="cv_upload">
                    Select a file to upload your CV:
                  </label>
                  <br></br>
                  {cvUploadError && (
                    <span className="error error-required">
                      {cvUploadError}
                    </span>
                  )}
                  {cvUploadSource === 'device' && (
                    <FilePond
                      credits={false}
                      allowMultiple={false}
                      allowReorder={false}
                      maxFiles={1}
                      name="cv_upload"
                      onupdatefiles={(fileItems) => {
                        handleCVUploadChange(fileItems, true);
                      }}
                      ref={cvRef}
                    />
                  )}
                </fieldset>
              )}

              {(miniAppFormJSON.CoverLetterRequired ||
                siteConfig.optionalCoverLetterUploadEnabled) && (
                <fieldset className="smartfeed-forms-fieldset">
                  <legend>
                    Upload your Cover Letter
                    {miniAppFormJSON.CoverLetterRequired && (
                      <span className="text-danger"> *</span>
                    )}
                  </legend>
                  <p>
                    The accepted file types are: Microsoft Word (.doc, .docx),
                    Text files (.txt, .rtf), PDF files (.pdf)
                  </p>
                  <p>The maximum file size allowed for upload is 4MB</p>
                  {!miniAppFormJSON.CoverLetterRequired && (
                    <p>(This field is optional)</p>
                  )}
                  <label htmlFor="cover_upload">
                    Select a file to upload your cover letter:
                  </label>
                  <br></br>
                  {coverUploadError && (
                    <span className="error error-required">
                      {coverUploadError}
                    </span>
                  )}
                  <FilePond
                    credits={false}
                    allowMultiple={false}
                    allowReorder={true}
                    maxFiles={1}
                    name="cover_upload"
                    onupdatefiles={(fileItems) => {
                      if (fileItems) {
                        handleCoverUploadChange(fileItems, true);
                      }
                    }}
                    ref={coverLetterRef}
                  />
                </fieldset>
              )}
              <fieldset className="smartfeed-forms-fieldset">
                <legend>Application statement</legend>
                <div
                  className="gdprstatement"
                  dangerouslySetInnerHTML={{
                    __html: gdprStatementJSON.Statement,
                  }}
                />
                {gdprStatementJSON.GDPRCustomMessage && (
                  <div
                    className="gdprstatement"
                    dangerouslySetInnerHTML={{
                      __html: gdprStatementJSON.GDPRCustomMessage,
                    }}
                  />
                )}
                <div className="d-flex">
                  <input
                    type="checkbox"
                    name="gdprstatement_accept"
                    id="gdprstatement_accept"
                    className="acceptGDPR_Check"
                    title="Agree to the application statement"
                    ref={register({
                      required: true,
                    })}
                    required
                  />
                  <label htmlFor="gdprstatement_accept" className="p-1">
                    {gdprStatementJSON.TickboxMessage}
                  </label>
                </div>
                {errors['gdprstatement_accept'] && (
                  <span className="error">
                    Please accept the GDPR message before applying.
                  </span>
                )}
              </fieldset>
              <div className="apply-btn-wrap">
                {errors && errors.length > 0 && (
                  <span className="error">
                    This form has errors, please scroll up to correct any
                    issues.
                  </span>
                )}
                <button type="submit" className="nav-action-sm">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : !hasCVUploadedStatus &&
        !showMiniAppFormFrameLoading &&
        miniAppFormSubmitted ? (
        <div className="loading-panel">
          <div className="loading-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span className="loading-text">
            Please wait while we upload your CV...
          </span>
        </div>
      ) : showMiniAppFormFrameLoading && miniAppFormSubmitted ? (
        <div className="loading-panel">
          <div className="loading-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span className="loading-text">
            Please wait while we load the next stage of the application
            process...
          </span>
        </div>
      ) : !showMiniAppFormFrameLoading &&
        !applicationFormLoading &&
        miniAppFormSubmitted ? (
        <div
          className="miniappformiframe"
          dangerouslySetInnerHTML={{
            __html: MiniAppFormIframe,
          }}
        />
      ) : (
        <div className="loading-panel">
          <div className="loading-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span className="loading-text">
            Please wait while we load the form's configuration...
          </span>
        </div>
      )}
    </>
  );
};

export default MiniAppFormForm;
